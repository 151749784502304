export type RouteDefinition = {
  component: string
  path: string
  as?: string
  name: string
  isCustom?: boolean
}

const plan = {
  plan: {
    component: '/nf/plans/[slug]',
    path: '/nf/plans/:slug',
    as: '/mesavantages/jeu/:slug',
    name: 'plan',
  },
}

const coupon = {
  birthdayCoupon: {
    component: '/nf/coupon-anniversaire',
    path: '/nf/coupon-anniversaire',
    name: 'birthdayCoupon',
  },
}

const culinary = {
  culinaryArticle: {
    component: '/_culinary/[slug]',
    path: '/_culinary/:slug',
    as: '/ar-:slug',
    name: 'culinaryArticle',
  },
}

const brand = {
  brand: {
    component: '/_brand/[slug]',
    path: '/_brand/:slug',
    as: '/m-:slug',
    name: 'brand',
  },
  brandSub: {
    component: '/_brand/[slug]/[slug2]',
    path: '/_brand/:slug/:slug2',
    as: '/m-:slug/:slug2',
    name: 'brandSub',
  },
}

const cheese = {
  allCheeses: {
    component: '/_cheese/all',
    path: '/_cheese/all',
    as: '/hf-tous-les-fromages',
    name: 'allCheeses',
  },
  allCheesesPage: {
    component: '/_cheese/all/[page]',
    path: '/_cheese/all/:page',
    as: '/hf-tous-les-fromages/p:page',
    name: 'allCheesesPage',
  },
  allCheesesTag: {
    component: '/_cheese/all/tag/[tag]',
    path: '/_cheese/all/tag/:tag',
    as: '/hf-tous-les-fromages-:tag',
    name: 'allCheesesTag',
  },
  allCheesesTagPage: {
    component: '/_cheese/all/tag/[tag]/[page]',
    path: '/_cheese/all/tag/:tag/:page',
    as: '/hf-tous-les-fromages-:tag/p:page',
    name: 'allCheesesTagPage',
  },
  cheese: {
    component: '/_cheese/[slug]',
    path: '/_cheese/:slug',
    as: '/f-:slug',
    name: 'cheese',
  },
}

const culture = {
  cultureArticle: {
    component: '/_culture/[slug]',
    path: '/_culture/:slug',
    as: '/ac-:slug',
    name: 'cultureArticle',
  },
}

const format = {
  format: {
    component: '/_format/[slug]',
    path: '/_format/:slug',
    as: '/p-:slug',
    name: 'format',
  },
}

const article = {
  articlesBrands: {
    component: '/_article/all/brands',
    path: '/_article/all/brands',
    as: '/hf-tous-les-articles-marques',
    name: 'articlesBrands',
  },
  articlesBrandsPage: {
    component: '/_article/all/brands/[page]',
    path: '/_article/all/brands/:page',
    as: '/hf-tous-les-articles-marques/p:page',
    name: 'articlesBrandsPage',
  },
  articlesBrandsTag: {
    component: '/_article/all/brands/tag/[tag]',
    path: '/_article/all/brands/tag/:tag',
    as: '/hf-tous-les-articles-marques--:tag',
    name: 'articlesBrandsTag',
  },
  articlesBrandsTagPage: {
    component: '/_article/all/brands/tag/[tag]/[page]',
    path: '/_article/all/brands/tag/:tag/:page',
    as: '/hf-tous-les-articles-marques--:tag/p:page',
    name: 'articlesBrandsTagPage',
  },
  articles: {
    component: '/_article/all',
    path: '/_article/all',
    as: '/hf-tous-les-articles',
    name: 'articles',
  },
  articlesPage: {
    component: '/_article/all/[page]',
    path: '/_article/all/:page',
    as: '/hf-tous-les-articles/p:page',
    name: 'articlesPage',
  },
  articlesTag: {
    component: '/_article/all/tag/[tag]',
    path: '/_article/all/tag/:tag',
    as: '/hf-tous-les-articles--:tag',
    name: 'articlesTag',
  },
  articlesTagPage: {
    component: '/_article/all/tag/[tag]/[page]',
    path: '/_article/all/tag/:tag/:page',
    as: '/hf-tous-les-articles--:tag/p:page',
    name: 'articlesTagPage',
  },
}

const health = {
  healthArticle: {
    component: '/_health/[slug]',
    path: '/_health/:slug',
    as: '/as-:slug',
    name: 'healthArticle',
  },
}

const folder = {
  folders: {
    component: '/_folder/home',
    path: '/_folder/home',
    as: '/td-tous-les-dossiers',
    name: 'folders',
  },
  foldersPage: {
    component: '/_folder/home/[page]',
    path: '/_folder/home/:page',
    as: '/td-tous-les-dossiers/p:page',
    name: 'foldersPage',
  },
  folder: {
    component: '/_folder/[slug]',
    path: '/_folder/:slug',
    as: '/d-:slug',
    name: 'folder',
  },
}

const plate = {
  plates: {
    component: '/_plate/home',
    path: '/_plate/home',
    as: '/ht-tous-les-plateaux',
    name: 'plates',
  },
  platesPage: {
    component: '/_plate/home/[page]',
    path: '/_plate/home/:page',
    as: '/ht-tous-les-plateaux/p:page',
    name: 'platesPage',
  },
  plate: {
    component: '/_plate/[slug]',
    path: '/_plate/:slug',
    as: '/plc-:slug',
    name: 'plate',
  },
}

const recipe = {
  recipe: {
    component: '/_recipe/[slug]',
    path: '/_recipe/:slug',
    as: '/r-:slug',
    name: 'recipe',
  },
}

const search = {
  search: {
    component: '/_search',
    path: '/_search',
    as: '/recherche',
    name: 'search',
  },
  searchPage: {
    component: '/_search/[page]',
    path: '/_search/:page',
    as: '/recherche/p:page',
    name: 'searchPage',
  },
  searchType: {
    component: '/_search/type/[type]',
    path: '/_search/type/:type',
    as: '/recherche/:type',
    name: 'searchType',
  },
  searchTypePage: {
    component: '/_search/type/[type]/[page]',
    path: '/_search/type/:type/:page',
    as: '/recherche/:type/p:page',
    name: 'searchTypePage',
  },
  searchTypeTag: {
    component: '/_search/type/[type]/tag/[tagString]',
    path: '/_search/type/:type/tag/:tagString',
    as: '/recherche/:type/:tagString',
    name: 'searchTypeTag',
  },
  searchTypeTagPage: {
    component: '/_search/type/[type]/tag/[tagString]/[page]',
    path: '/_search/type/:type/tag/:tagString/:page',
    as: '/recherche/:type/:tagString/p:page',
    name: 'searchTypeTagPage',
  },
}

const tag = {
  tag: {
    component: '/_tag/[slug]',
    path: '/_tag/:slug',
    as: '/t-:slug',
    name: 'tag',
  },
  tagType: {
    component: '/_tag/[slug]/[type]',
    path: '/_tag/:slug/type/:type',
    as: '/t-:slug/t-:type',
    name: 'tagType',
  },
  tagPage: {
    component: '/_tag/[slug]/[page]',
    path: '/_tag/:slug/:page',
    as: '/t-:slug/p:page',
    name: 'tagPage',
  },
  tagTypePage: {
    component: '/_tag/[slug]/[type]/[page]',
    path: '/_tag/:slug/type/:type/:page',
    as: '/t-:slug/t-:type/p:page',
    name: 'tagTypePage',
  },
}

const legals = {
  credits: {
    component: '/nf/credits',
    path: '/nf/credits',
    name: 'credits',
  },
}

const tmpPages = {}

const routes = {
  ...article,
  ...search,
  ...brand,
  ...cheese,
  ...culinary,
  ...culture,
  ...health,
  ...folder,
  ...plate,
  ...recipe,
  ...tag,
  ...tmpPages,
  ...plan,
  ...format,
  ...coupon,
  ...legals,

  home: {
    component: '/',
    path: '/',
    name: 'home',
  },
  page: {
    component: '/[...slug]',
    path: '/:slug+',
    name: 'page',
  },
} as const

export default routes
