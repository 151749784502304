const trackEvent = (event: any) => {
  if (
    typeof window === 'undefined' ||
    (window as any).dataLayer === undefined
  ) {
    return null
  }

  return (window as any).dataLayer.push(event)
}

export enum TrackingContentGroup {
  ACCOUNT = 'mon compte',
  MAINTENANCE = 'maintenance',
  ALL_CHEESES = 'fromages',
  CHEESE = 'fromage',
  BRAND = 'marque',
  SOLO_BRAND = 'site marque',
  TAG = 'tag',
  SEARCH = 'recherche',
  RECIPE = 'recette',
  PLATE = 'planche',
  ALL_PLATES = 'plaches',
  ARTICLE = 'article',
  ALL_ARTICLES = 'articles',
  FOLDER = 'dossier',
  ALL_FOLDER = 'dossiers',
  NOT_FOUND = '404',
  POSITIVE_FOOD = 'positive food',
  HP_RECIPES = 'home recettes',
  HP_CHEESES_CULTURE = 'home culture',
  HP_CHEESES = 'home fromages',
  GENERIC = 'generic',
  HP = 'homepage',
  SIGN_UP = 'inscription',
  PASSWORD_FORGOTTEN = 'mot de passe oublié',
  PLAN = 'plan',
  GAME = 'Jeu concours',
}
export enum TrackingUserType {
  VISITOR = 'visiteur',
  MEMBER = 'membre',
}
export enum TrackingCardPosition {
  HERO = 'hero',
  CONTENT = 'content',
}
export enum TrackingCategoryType {
  BENEFITS = 'Mes avantages',
  SOLO = 'Nos fromages',
}

export enum TrackingBrandName {
  CAPRICE = 'CAPRICE DES DIEUX',
}

export enum TrackingUrlException {
  JC_CDD = '/m-caprice-des-dieux/la-saint-valentin',
}

export enum TrackingBannerInteraction {
  IMPRESSION = 'impression',
  CLICK = 'clic',
}

export type TrackingPageProps = {
  brand?: TrackingBrandName | string
  group?: TrackingContentGroup
  userType?: TrackingUserType
  userId?: string
  page?: string
  section?: TrackingCategoryType
  hideRubrique?: boolean
}

export default {
  page: ({
    brand,
    group,
    userType,
    userId,
    page,
    section,
    hideRubrique,
  }: TrackingPageProps) =>
    trackEvent({
      event: 'page_ready',
      currency: 'EUR',
      ...(!hideRubrique && {
        Rubriques: section,
      }),
      content_section: section,
      page_brand: brand,
      content_group: group,
      content_page: page,
      user_type: userType ?? TrackingUserType.VISITOR,
      user_id: userId,
    }),
  pageview: (url: string) =>
    trackEvent({
      event: 'page_view',
      url,
    }),
  authPopin: {
    login: () => {
      return trackEvent({
        event: 'click_account_login', // click on "Je me connecte"
      })
    },
    signup: () => {
      return trackEvent({
        event: 'click_sign_up',
      })
    },
    direct: (method = 'direct') => {
      return trackEvent({
        event: 'login',
        method: method,
      })
    },
  },
  header: {
    menu: (label: string, level: number) =>
      trackEvent({
        event: 'click_menu',
        button_text: label,
        menu_level: level,
      }),
  },
  footer: {
    menu: (label: string) =>
      trackEvent({
        event: 'click_footer',
        link_text: label,
      }),
  },
  signup: {
    congrats: () =>
      trackEvent({
        event: 'sign_up',
      }),
  },
  social: (type: string, page: string) =>
    trackEvent({
      event: 'click_social_media',
      button_text: type,
      button_location: page,
    }),
  search: (term: any, page: string, type: string, results?: number) => {
    return trackEvent({
      event: 'search',
      search_term: term,
      search_type: type,
      search_bar_location: page,
      ...(results && {
        nb_search_results: results,
      }),
    })
  },
  tag: (text: string, page: string) =>
    trackEvent({
      event: 'click_tag',
      tag_text: text,
      tag_location: page,
    }),
  newsletter: (page: string) =>
    trackEvent({
      event: 'newsletter_subscription',
      newsletter_location: page,
    }),
  brand: {
    card: (brand: string, card: string) =>
      trackEvent({
        event: 'click_brand_card',
        brand_name: brand,
        card_name: card,
      }),
    details: (type: string, name: string) =>
      trackEvent({
        event: 'click_detail_facebook',
        element_type: (TrackingContentGroup as any)[`${type}`],
        element_name: name,
      }),
    range: () =>
      trackEvent({
        event: 'click_product_range',
      }),
  },
  cards: {
    hero: (text: string) =>
      trackEvent({
        event: 'click_hero',
        button_text: text,
      }),
    highlight: (title: string, text: string) =>
      trackEvent({
        event: 'click_highlight',
        highlight_title: title,
        button_text: text,
      }),
    block: (block?: string, type?: string, name?: string, position?: string) =>
      trackEvent({
        event: 'click_card_block',
        block_name: block,
        block_type: type,
        card_name: name,
        card_position: position,
      }),
  },
  purchase: (product: string, brand: string, method: string) =>
    trackEvent({
      event: 'click_purchase',
      product_name: product,
      brand_name: brand,
      purchase_method: method,
    }),
  wishlist: (type?: string, name?: string, brand?: string) =>
    trackEvent({
      event: 'add_wishlist',
      element_type: (TrackingContentGroup as any)[`${type}`] ?? '',
      element_name: name ?? '',
      brand_name: brand ?? '',
    }),
  share: (type?: string, name?: string, method?: string) =>
    trackEvent({
      event: 'share',
      element_type: (TrackingContentGroup as any)[`${type}`] ?? '',
      element_name: name ?? '',
      method: method ?? '',
    }),
  print: (type: string, name: string) =>
    trackEvent({
      event: 'print_content',
      element_type: (TrackingContentGroup as any)[`${type}`],
      element_name: name,
    }),
  filter: (name: string, value?: string) =>
    trackEvent({
      event: 'add_filter',
      filter_name: name,
      filter_value: value,
    }),
  cta: (text?: string, location?: string) =>
    trackEvent({
      event: 'click_cta',
      button_text: text,
      cta_location: location,
    }),
  faq: (theme?: string, question?: string) =>
    trackEvent({
      event: 'click_faq',
      faq_theme: theme,
      faq_question: question,
    }),
  game: () => trackEvent({ event: 'click_contest_game' }),
  positive: {
    content: (text: string) =>
      trackEvent({
        event: 'select_content',
        content_type: text,
      }),
  },
  coupons: {
    print: (name: string, brand?: string, value?: number) =>
      trackEvent({
        event: 'print_coupon',
        coupon_name: name,
        brand_name: brand,
        coupon_value: value,
      }),
    commerce: () =>
      trackEvent({
        ecommerce: null,
      }),
    add: (name: string, id: number, brand?: string, price?: number) =>
      trackEvent({
        event: 'add_to_cart',
        currency: 'EUR',
        value: price,
        ecommerce: {
          items: [
            {
              item_id: id,
              item_name: name,
              item_brand: brand,
              quantity: 1,
              price: price,
            },
          ],
        },
      }),
    remove: (name: string, id: number, brand?: string, price?: number) =>
      trackEvent({
        event: 'remove_from_cart',
        currency: 'EUR',
        value: price,
        ecommerce: {
          items: [
            {
              item_id: id,
              item_name: name,
              item_brand: brand,
              quantity: 1,
              price: price,
            },
          ],
        },
      }),
    printAll: (
      value: number,
      items: {
        name?: string
        id?: number
        brand?: string
        price?: number
      }[]
    ) =>
      trackEvent({
        event: 'view_cart',
        currency: 'EUR',
        value: value,
        ecommerce: {
          items: items.map((item) => {
            return {
              item_id: item.id,
              item_name: item.name,
              item_brand: item.brand,
              quantity: 1,
              price: item.price,
            }
          }),
        },
      }),
  },
  bannerSelfPromo: (
    campaign: string,
    format: string,
    url: string,
    interaction: TrackingBannerInteraction
  ) =>
    trackEvent({
      event: 'self_promotion',
      currency: 'EUR',
      sp_campaign_name: campaign,
      sp_format: format,
      sp_url: url,
      sp_interaction: interaction,
    }),
}
