import React, { FC } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
export const persistConfig: PersistConfig<any> = {
  key: 'root',
  version: 9,
  storage,
  whitelist: ['app', 'auth', 'coupons', 'member', 'solo'],
  migrate: (state, currentVersion) => {
    if (state?._persist && state?._persist?.version !== currentVersion) {
      return Promise.resolve({
        _persist: {
          version: currentVersion,
          rehydrated: false
        }
      });
    }
    return Promise.resolve(state);
  }
};
export const PersistWrapper: FC<{
  children: React.ReactNode;
  persistor: any;
}> = ({
  children,
  persistor
}) => <PersistGate persistor={persistor} loading={children}>
    {children}
  </PersistGate>;